@import "../../Brakpoints.scss";

.game-end-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;


  .game-over-wrap {
    background: rgb(37, 16, 67);
    background: linear-gradient(90deg, rgba(37, 16, 67, 1) 0%, rgba(52, 44, 121, 0) 100%);
    display: flex;
    justify-content: center;
    padding: 30px;
    align-self: stretch;

    margin-top: 10%;
  }

  .game-over {
    background-image: url("/assets/images/game_over.png");
    width: 713px;
    height: 103px;
    background-repeat: no-repeat;
    max-width: 100%;
    background-size: contain;
    background-position: center;
  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 50px;
  }

  .btn-quit {
    background-image: url("/assets/images/btn-quit.png");
    width: 356px;
    height: 71px;
    background-repeat: no-repeat;
    margin-top: 56px;
  }

  .btn-play-again {
    background-image: url("/assets/images/btn-replay.png");
    width: 412px;
    height: 100px;
    background-repeat: no-repeat;
    position: relative;

    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 56px;

    .text {
      color: white;
      font-size: 26px;
      font-weight: bold;
      position: relative;
      right: 30px;
      top: -10px;
      text-transform: uppercase;
    }
  }
}

.game-end-claim-price {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;


  .game-over-wrap {
    background: rgb(37, 16, 67);
    background: linear-gradient(90deg, rgba(37, 16, 67, 1) 0%, rgba(52, 44, 121, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    align-self: stretch;
    align-items: center;
    margin-top: 50px;
    position: relative;
  }

  .claim-price-text {
    background-image: url("/assets/images/congratulations.png");
    max-width: 932px;
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    top: -50px;
  }

  .subtitle {
    font-size: 30px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 250px;

    @media (max-width: $mobile) {
      font-size: 20px;
      top: 200px;
    }
  }

  .btn-claim {
    background-image: url("/assets/images/btn-claim.png");
    width: 570px;
    height: 258px;
    background-repeat: no-repeat;

    display: flex;
    justify-content: right;
    align-items: center;

    position: relative;

    .text {
      color: white;
      font-size: 26px;
      font-weight: bold;
      position: absolute;
      right: 115px;
      top: 117px;
    }
  }
}