@import "../../Brakpoints.scss";

.game-page {
  display: grid;
  height: 100vh;
  grid-template-columns: 200px auto 200px;
  grid-template-rows: 60px auto 0;
  grid-template-areas: "top top top" "cardWrapper cardWrapper cardWrapper" ". . .";
  z-index: 1;
  position: relative;

  @media (max-width: $bingo) {
    grid-template-columns: 100px auto 100px;
    grid-template-areas: "leftBar top rightBar" "cardWrapper cardWrapper cardWrapper" ". . .";
  }

  .pass {
    grid-area: pass;
    background-image: url("/assets/images/pass.png");

    position: absolute;
    top: 10px;
    width: 100px;
    height: 130px;
    background-repeat: no-repeat;
    right: 10px;
    z-index: 99;
    cursor: pointer;

    @media (max-width: $bingo) {
      transform: scale(0.7);
    }
  }

  .main-area {
    grid-area: cardWrapper;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "question"
      "cardWrapper";
    justify-items: center;
    align-items: start;
    position: relative;
    width: 772px;
    align-self: center;
    justify-self: center;
    z-index: 2;

    @media (max-width: $bingo) {
      align-self: flex-end;
      width: 100%;
      align-self: center;
      margin-top: 75px;
      grid-template-areas:
        "podium"
        "question"
        "cardWrapper";
    }



    .counterBar {
      position: absolute;
      top: 53px;
      left: -5px;
      width: 70px;
      height: 70px;
      z-index: 5;
      right: 0;
      margin: 0 auto;


      transform: scaleX(-1);

      @media (max-width: $bingo) {
        top: 9px;
        left: -3px;
        width: 49px;
        height: 52px;
      }


      @media (max-width: $mobile) {
        top: 26px;
        left: -1px;
        width: 35px;
        height: 41px;
      }

      @media (max-width: $smallMobile) {
        top: 85px;
        left: -2px;
        width: 32px;
        height: 41px;
      }



      // @media (max-width: $mobile) {
      //   top: 10px;
      //   left: -5px;
      //   width: 52px;
      //   height: 52px;
      // }
    }

    .podium {
      pointer-events: none;

      grid-template-areas: podium;
      background-image: url("/assets/images/themes/theme_80s.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      margin: 0 auto;

      width: 1651px;
      height: 900px;
      max-width: 100%;


      @media (max-width: $bingo) {
        max-width: 550px;
        bottom: -45px;
      }


      @media (max-width: $mobile) {
        max-width: 400px;
        bottom: -65px;
      }

      @media (max-width: $smallMobile) {
        max-width: 350px;
        bottom: -125px;
      }
    }

    .question {
      grid-area: question;
      color: white;
      font-weight: bold;
      font-size: 23px;
      text-shadow: 15px -5px 5px #0000009c;
      text-align: center;
      z-index: 1;
      line-height: 32px;
      position: relative;

      @media (max-width: $bingo) {
        bottom: auto;
        padding: 5px 10px;
      }

      @media (max-width: $mediumBtnMobile) {
        margin-bottom: 140px;
      }
      @media (max-width: $smallMobile) {
        margin-bottom: 50px;
      }
    }

    .card-wrapper {
      grid-area: cardWrapper;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      max-width: 1000px;
      width: 100%;
      position: relative;

      .cwrap {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        margin-top: 120px;
        margin-bottom: 20px;
        position: relative;

        @media (max-width: $bingo) {
          margin-top: 35px;
        }

        .shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          transform: perspective(10px) rotateX(1deg);
          background: radial-gradient(circle,
              rgba(255, 255, 255, 0) 56%,
              #ffffff57 99%);
          box-shadow: 1px 2px 2px #ffffff17;
          padding: 25px;
          top: -20px;

          @media (max-width: $bingo) {
            display: none;
            transform: none;
          }
        }

        .card {
          width: 100%;
          height: 350px;
          background-color: transparent;
          border: none;
          border-radius: 0;
          margin: 0 10px 0 10px;
          cursor: pointer;
          z-index: 5;
          position: relative;

          @media (max-width: $bingo) {
            transform: none;
          }

          &.left,
          &.right {
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-image 0.35s;
            backface-visibility: hidden;
            perspective: 400px;

            @media (max-width: $bingo) {
              max-width: 200px;
            }

            .bar-chart {
              display: none;
              position: absolute;
              bottom: 0;
              height: 150px;
              background: red;
              width: 100%;

              //transform: perspective(500px) rotateY(0deg) rotateZ(2deg) rotateX(45deg);

              .inner {
                position: absolute;
                background: white;
                width: 100%;
                height: 100%;
                bottom: 0;
              }
            }

            .text {
              color: #7b78ff;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              // padding-bottom: 30px;
              // padding-left: 12px;
              // padding-right: 12px;
              padding: 12px;
              letter-spacing: -0.5px;
              line-height: 22px !important;
              width: 240px;
              height: 320px;
              //margin-bottom: 5px;
              //margin-left: 40px;
              z-index: 6;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;

              .label {
                position: absolute;
                top: -1px;
                left: auto;
                right: auto;
                color: white;
                padding: 5px 7px;
                border-radius: 0 0 3px 3px;
              }
            }

            .wrong,
            .correct {
              .text {
                display: none;
              }
            }
          }

          &.left {

            .text {
              //transform: skewX(-31deg) rotateX(-10deg);
            }

            // background-image: url("/assets/images/mobile/card_blank.png");
            background-image: url("/assets/images/cards/card_blank.png");

            &.active {
              background-image: url("/assets/images/cards/card_selected.png");
            }

            &.active.correct,
            &.correct {
              background-image: url("/assets/images/cards/card_correct.png");

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_correct.png");
              }

              .text {
                display: none;
              }
            }

            &.active.correct-blank,
            &.correct-blank {
              background-image: url("/assets/images/cards/card_correct_blank.png");

              .text {
                color: #0e6f2f;
              }

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_correct_blank.png");

                .text {
                  color: #0e6f2f;
                }
              }
            }

            &.active.wrong,
            &.wrong {
              background-image: url("/assets/images/cards/card_wrong.png");

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_wrong.png");
              }

              .text {
                display: none;
              }
            }

            &.active.wrong-blank,
            &.wrong-blank {
              background-image: url("/assets/images/cards/card_wrong_blank.png");

              .text {
                color: #8f1a0b;
              }

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_wrong_blank.png");

                .text {
                  color: #8f1a0b;
                }
              }
            }

            @media (max-width: $bingo) {
              background-image: url("/assets/images/mobile/card_blank.png");

              .text {
                transform: none;
                margin: 0;
              }

              &.active {
                background-image: url("/assets/images/mobile/card_selected.png");
              }
            }

            &.correct-blank .label {
              background-color: #0e6f2f;
            }

            &.wrong-blank .label {
              background-color: #c62215;
            }

            .label {
              // transform: perspective(300px) rotateY(-20deg) rotateZ(0deg) rotateX(43deg);
              // left: 62px !important;
              // top: 10px !important;

              @media (max-width: $bingo) {
                transform: translateX(-50%);
                left: 50%;
                top: 61px;
                font-weight: bold;
              }
            }
          }

          &.right {
            // background-image: url("/assets/images/mobile/card_blank.png");
            background-image: url("/assets/images/cards/card_blank_new.png");

            .text {
              line-height: 20px;
            }

            &.active {
              background-image: url("/assets/images/cards/card_selected.png");
            }

            &.active.correct,
            &.correct {
              background-image: url("/assets/images/cards/card_correct.png");

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_correct.png");
              }

              .text {
                display: none;
              }
            }

            &.active.correct-blank,
            &.correct-blank {
              background-image: url("/assets/images/cards/card_correct_blank.png");

              .text {
                color: #0e6f2f;
              }

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_correct_blank.png");

                .text {
                  color: #0e6f2f;
                }
              }
            }

            &.active.wrong,
            &.wrong {
              background-image: url("/assets/images/cards/card_wrong.png");

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_wrong.png");
              }

              .text {
                display: none;
              }
            }

            &.active.wrong-blank,
            &.wrong-blank {
              background-image: url("/assets/images/cards/card_wrong_blank.png");

              .text {
                color: #8f1a0b;
              }

              @media (max-width: $bingo) {
                background-image: url("/assets/images/mobile/card_wrong_blank.png");

                .text {
                  color: #8f1a0b;
                }
              }
            }

            .text {
              // margin-left: 0;
              // margin-right: 30px;

              margin-left: 0;
              margin-right: 0;
             // transform: skewX(20deg) rotateX(0deg) translateX(-10px);
             // padding-right: 20px;
            }

            @media (max-width: $bingo) {
              background-image: url("/assets/images/mobile/card_blank.png");

              .text {
                transform: none;
                margin: 0;
              }

              &.active {
                background-image: url("/assets/images/mobile/card_selected.png");
              }
            }

            &.correct-blank .label {
              background-color: #0e6f2f;
            }

            &.wrong-blank .label {
              background-color: #c62215;
            }

            .label {
              position: absolute;
              top: 90px;
              left: 65px;
              color: white;
              padding: 5px 7px;
              border-radius: 3px;
              width: 60px;
              text-align: center;
              background-color: #0e6f2f;
              transform: skewX(22deg) rotateX(-10deg);
              font-size: 15px;

              @media (max-width: $bingo) {
                transform: translateX(-50%);
                left: 50%;
                top: 58px;
              }
            }
          }
        }
      }
    }
  }

  .top {
    grid-area: top;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    .answered,
    .remain {
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      color: transparent;
    }

    .answered {
      background-image: url("/assets/images/questionui_1.png");
    }

    .remain {
      background-image: url("/assets/images/questionui_2.png");
    }

    .current {
      font-size: 20px;
      color: #8f6b8c;
      font-weight: 600;
      margin-right: 5px;
      // margin-top: -8px;
    }
  }

  .left-bar,
  .right-bar {
    z-index: 1;
    position: absolute;
    top: 7%;

    @media (max-width: $bingo) {
      transform: scale(0.5);
      top: 20%;
    }

    .podium {
      background-repeat: no-repeat;
      position: absolute;
      z-index: 1;
    }

    .img {
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      z-index: 2;
    }
  }

  .left-bar {
    // grid-area: leftBar;
    left: 0;

    @media (max-width: $bingo) {
      left: -48px;
    }

    @media (max-width: $mediumBtnMobile) {
      margin-top: 60px;
    }
    @media (max-width: $smallMobile) {
      margin-top: 80px;
    }

    .podium {
      background-image: url("/assets/images/podium_left.png");
      left: 0;
      right: 0;
      bottom: 0;
      top: 230px;

      width: 259px;
      height: 348px;

    }

    .img {
      background-image: url("/assets/images/prizefund_scenery.png");
      width: 180px;
      height: 195px;
      top: 104px;

      @media (max-width: $bingo) {
        // width: 100px;
      }
    }
  }

  .right-bar {
    right: 0;

    @media (max-width: $bingo) {
      right: -48px;
    }

    @media (max-width: $mediumBtnMobile) {
      margin-top: 60px;
    }
    @media (max-width: $smallMobile) {
      margin-top: 80px;
    }

    .podium {
      background-image: url("/assets/images/podium_right.png");
      right: 0px;
      bottom: 0px;
      top: 230px;

      width: 259px;
      height: 348px;
    }

    .img {
      background-image: url("/assets/images/playersleft_scenery.png");
      width: 162px;
      height: 204px;
      top: 98px;
      right: -5px;


      @media (max-width: $bingo) {
        // top: 0;
        // margin-left: 16px;
      }
    }
  }
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  background: #000000b0;
  backdrop-filter: blur(1px);

  .cont {
    display: grid;
    place-items: center;
  }

  .icon {
    width: 500px;
    height: 500px;
    margin-top: -80px;
  }

  // .btn {
  //   width: 350px;
  //   height: 125px;
  //   margin-top: -80px;
  // }

  &.spectating-modal {
    .icon {
      background: url("/assets/images/spectate_icon_large.png") no-repeat center;
      background-size: contain;
    }

    .btn {
      background: url("/assets/images/spectate_bar.png") no-repeat center;
      background-size: contain;
    }
  }

  &.eliminated-modal {
    .icon {
      background: url("/assets/images/eliminated_icon.png") no-repeat center;
      background-size: contain;
    }

    .btn {
      background: url("/assets/images/eliminated_bar.png") no-repeat center;
      background-size: contain;
    }
  }

  &.pass-modal,
  &.claim-price-modal {
    .clock {
      font-size: 53px;
      color: yellow;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .content2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      text-align: center;
    }
  }

  &.pass-modal {
    .btn-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 860px;

      @media (max-width: $playtech) {
        flex-direction: row;
        align-items: center;
      }

      @media (max-width: $mediumBtnMobile) {
        flex-direction: column;
      }
      @media (max-width: $smallMobile) {
        flex-direction: column;
      }

      .btn {
        width: 394px;
        height: 78px;
        border-radius: 20px;

        @media (max-width: $bingo) {
          margin-top: 45px;
        }

        &.use-the-pass {
          background-image: url("/assets/images/btn-use-the-pass.png");
        }

        &.cancel {
          background-image: url("/assets/images/btn-cancel.png");
        }
      }
    }

    .content-wrap {
      background: #25052d;
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .content {
      color: white;
      font-size: 26px;
      font-weight: bold;

      span {
        color: #ffd647;
      }
    }

    .number {
      width: 173px;
      height: 57px;
      color: transparent;
      background-image: url("/assets/images/50text.png");
      margin-top: 20px;
    }
  }

  &.claim-price-modal {
    .btn-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 860px;

      // @media (max-width: $mobile) {
      //   flex-direction: row;
      //   align-items: center;
      // }

      @media (max-width: $playtech) {
        flex-direction: row;
        align-items: center;
      }

      @media (max-width: $mediumBtnMobile) {
        flex-direction: column;
      }
      @media (max-width: $smallMobile) {
        flex-direction: column;
      }

      .btn {
        width: 100%;
        height: 100px;
        border-radius: 20px;

        @media (max-width: $bingo) {
          margin-top: 10px;
        }

        &.claim-price {
          background-image: url("/assets/images/btn-primary.png");
          background-repeat: round;

          display: flex;
          justify-content: right;
          align-items: center;
          text-align: right;

          .text {
            color: white;
            font-size: 23px;
            font-weight: bold;
            text-transform: uppercase;
            position: relative;
            right: 30px;
            top: -8px;
          }
        }

        &.play-on {
          background-image: url("/assets/images/btn-playon.png");
          background-repeat: round;
        }
      }
    }


    .content-wrap {
      background: #25052d;
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .content {
      color: white;
      font-size: 26px;
      font-weight: bold;

      span {
        color: #ffd647;
      }
    }

    .number {
      font-size: 79px;
      font-weight: bold;

      background: #F0FC37;
      background: linear-gradient(to bottom, #F0FC37 0%, #FFA800 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

/*diffrent game content load*/

.eighties .game-page .main-area .podium {
  background-image: url("/assets/images/themes/theme_80s.png");
}

.nineties .game-page .main-area .podium {
  background-image: url("/assets/images/themes/theme_90s.png");
}

.noughties .game-page .main-area .podium {
  background-image: url("/assets/images/themes/theme_00s.png");
}

.movies .game-page .main-area .podium {
  background-image: url("/assets/images/themes/theme_hollywood.png");
}

/*custom mobile styles*/

// @media (max-width: $bingo) {
//   .modal .icon {
//     width: 340px;
//     height: 340px;
//     margin-top: -70px;
//   }

//   .modal .btn {
//     width: 270px;
//     height: 95px;
//     margin-top: -70px;
//   }
// }

@media (max-width: $mobile) {

  /* CSS fix to iOS devices */
  .layers {
    position: relative;
    height: -webkit-fill-available;
    height: fill-available;
    height: stretch;
  }

  .game-page {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: stretch;
  }

  @supports not (-webkit-touch-callout: none) {

    .layers,
    .game-page {
      min-height: 100vh;
    }
  }

  /* CSS fix to iOS devices */

  .game-page .top {
    padding: 25px 0px 0 15px;
  }

  .game-page .main-area {
    margin-top: 55px;
  }

  .game-page .top .answered,
  .game-page .top .remain {
    width: 10px;
    height: 15px;
    margin-right: 5px;
  }

  .game-page .top .current {
    font-size: 17px;
    margin-top: -2px;
  }

  .modal .icon {
    width: 320px;
    height: 320px;
    margin-top: -70px;
  }

  .modal .btn {
    width: 270px;
    height: 95px;
    margin-top: -70px;
  }
}

@media (max-width: $bingo) {

  .game-page .main-area .card-wrapper .cwrap .card.left .text,
  .game-page .main-area .card-wrapper .cwrap .card.right .text {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0 !important;
    width: 135px !important;
    transform: translateY(7px);
    line-height: 20px;
  }

  .entry-page .play-button-wrap .play-button .text
  {
    bottom: 15px !important;
  }

  .game-page .main-area .card-wrapper .cwrap .card.right .label {
    font-weight: bold;
  }
}

@media (max-width: $smallMobile) {
  .game-page .top {
    justify-content: flex-start;
  }
}

@media (max-width: $smallMobile) {

  .game-page .main-area .card-wrapper .cwrap .card.left,
  .game-page .main-area .card-wrapper .cwrap .card.right {
    max-width: 135px;
  }

  .game-page .main-area .card-wrapper .cwrap .card.right .label {
    font-size: 14px;
    top: 97px;
  }

  .game-page .main-area .card-wrapper .cwrap .card.left .label {
    font-size: 14px;
    top: 98px;
  }

  .game-page .main-area .card-wrapper .cwrap .card.left .text,
  .game-page .main-area .card-wrapper .cwrap .card.right .text {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0 !important;
    font-size: 12px;
    line-height: 18px;
  }
}

//btn-responsive update

@media (max-width: $mediumBtnMobile) {

  .modal.pass-modal .btn-wrapper .btn {
    width: 340px;
    height: 65px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.modal.pass-modal .btn-wrapper .btn {
  margin-top: 20px;
}
.btn-quit{
  width: 340px !important;
  //height: 70px !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-play-again
{
  width: 350px !important;
  //height: 70px !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
}


@media (max-width: $smallBtnMobile) {

  .modal.pass-modal .btn-wrapper .btn {
    width: 300px;
    height: 55px;
}

.btn-quit{
  width: 300px;
  height: 65px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-quit{
  width: 310px !important;
 // height: 65px !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-play-again
{
  width: 325px !important;
 // height: 80px !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
}