@import "../../Brakpoints.scss";


.entry-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 1fr 0px;
  grid-template-areas:
    "top top"
    ". contentWrap"
    ". .";
  height: 100vh;

  @media (max-width: $bingo) {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr 50px;
    grid-template-areas:
      "top"
      "contentWrap"
      ".";
  }

  .top-component {
    grid-area: top;
  }

  .content-wrap {
    grid-area: contentWrap;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    align-items: end;
    justify-content: end;
    margin-right: 60px;
    max-height: 625px;
    align-self: center;
    justify-self: center;
    margin-top: -37px;
    z-index: 3;

    @media (max-width: $bingo) {
      align-self: flex-end;
      margin: 0 auto;
      padding: 20px;
    }

    .fiftyimg {
      background-image: url("/assets/images/5050_title.png");
      width: 122px;
      height: 82px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: $bingo) {
        background-size: contain;
        margin: 0;
      }
    }

    .game-logo {
      background-image: url("/assets/images/musicquiz_title.png");
      max-width: 409px;
      width: 100%;
      height: 79px;
      margin: 0 auto;
      margin-top: 10px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .description {
      color: white;
      text-align: center;
      line-height: 21px;
      position: relative;
      z-index: 3;

      font-size: 17px;
      font-weight: bold;
    }
  }

  .music-image {
    background-image: url("/assets/images/themes/theme_music2.png");
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-size: contain;
    height: 100vh;
    background-repeat: no-repeat;
    background-position-x: -11%;
    background-position-y: 105px;
    touch-action: none;
    pointer-events: none;



    @media (max-width: $bingo) {
      bottom: auto;
      top: 0;
      background-position-x: -101%;
      background-position-y: 0;

      height: 46%;
      opacity: 0.5;
      // mask-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   right bottom,
      //   color-stop(0, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 1)),
      //   color-stop(1, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 0))
      // );
    }
  }

  .play-button-wrap {
    position: relative;
    margin-top: 10px;
    cursor: pointer;

    .cup-image {
      background-image: url("/assets/images/prizefund_startscreen.png");
      width: 600px;
      position: absolute;
      height: 206px;
      z-index: 1;
      pointer-events: none;
      top: -5px;
      right: -95px;

      @media (max-width: $bingo) {
        width: 100%;
        top: 22px;
        right: -27px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .price-fund {
      background-image: url("/assets/images/jackpot-text.png");
      width: 254px;
      height: 129px;
      z-index: 2;
      position: relative;
      background-repeat: no-repeat;
    }

    .server-notice {
      background: rgb(52, 44, 121);
      background: linear-gradient(90deg, rgba(52, 44, 121, 0.59) 0%, rgba(52, 44, 121, 1) 50%, rgba(52, 44, 121, 0.5919411709996498) 100%);
      padding: 10px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .play-button {
      background-image: url("/assets/images/btn-primary.png");
      width: 412px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 3;
      position: relative;
      margin: 0 auto;

      @media (max-width: $mobile) {
        width: 100%;
      }

      .text {
        color: white;
        font-weight: bolder;
        font-size: 26px;
        padding-right: 20px;
        bottom: 8px;
        right: 15px;
        position: relative;
      }

      &.disabled {
        background-image: url("/assets/images/btn-disabled.png");
      }
    }
  }

  .footer {
    // position: absolute;
    // bottom: 15px;
    width: 100vw;
    z-index: 9999;
    display: flex;
    // justify-content: flex-end;
    align-items: center;

    @media (max-width: $mobile) {
      justify-content: flex-start;
      // justify-content: end;

      position: fixed;
      bottom: 15px;
    }

    .exit {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      display: none;

      @media (max-width: $mobile) {
        display: block;
      }

      .icon {
        background: url("/assets/images/exit_button.png") no-repeat center;
        background-size: contain;
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}

/*diffrent game content load*/

.eighties .entry-page .content-wrap .game-logo {
  background-image: url("/assets/images/desktop-titles/80s_title.png");
}

.eighties .entry-page .music-image {
  background-image: url("/assets/images/themes/theme_80s2.png");
}

.nineties .entry-page .content-wrap .game-logo {
  background-image: url("/assets/images/desktop-titles/90s_title.png");
}

.nineties .entry-page .music-image {
  background-image: url("/assets/images/themes/theme_90s2.png");
}

.noughties .entry-page .content-wrap .game-logo {
  background-image: url("/assets/images/desktop-titles/00s_title.png");
}

.noughties .entry-page .music-image {
  background-image: url("/assets/images/themes/theme_00s2.png");
}

.movies .entry-page .content-wrap .game-logo {
  background-image: url("/assets/images/desktop-titles/movies_title.png");
}

.movies .entry-page .music-image {
  background-image: url("/assets/images/themes/theme_hollywood2.png");
}


/*custom mobile styles*/

// @media (max-width: 900px) {
//   .entry-page .content-wrap {
//     margin: 0 auto 150px;
//   }
//   .entry-page .music-image {
//     background-position-x: 0;
//     background-position-y: 0;
//     height: 46%;
//   }
// }
@media (max-width: $mobile) {

  /* CSS fix to iOS devices */
  .layers {
    position: relative;
    height: 100vh !important;
    height: fill-available;
    height: stretch;
    overflow-y: hidden;
  }

  .entry-page {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: stretch;
  }

  @supports not (-webkit-touch-callout: none) {

    .layers,
    .entry-page {
      min-height: 100vh;
    }
  }
}